// → styled link with label and arrow

import React from "react";
import styled from "styled-components";
import { mainGrey, mainOrange, screen, transHover } from "../common/variables";
import { Link } from "gatsby";
import RightArrow from "../../images/svg/right-arrow.svg";

const Wrapper = styled(Link)`
  position: relative;
  z-index: 2;
  display: flex;
  color: ${mainGrey};
  font-size: 1rem;
  font-weight: 800;
  position: relative;
  z-index: 2;
  margin-top: 5px;
  line-height: 1.6;
  transition: ${transHover};
  flex-direction: ${props => (props.long ? "column" : "row")};
  align-items: ${props => (props.long ? "flex-start" : "center")};
  padding-right: ${props => (props.long ? "50px" : "0")};
  @media ${screen.xsmall} {
    align-items: center;
    font-size: 1.05rem;
    flex-direction: row;
    padding-right: 0;
  }
  @media ${screen.xlarge} {
    font-size: 1.1rem;
  }

  svg {
    width: 72px;
    margin-left: ${props => (props.long ? "0" : "15px")};
    margin-top: ${props => (props.long ? "15px" : "0")};
    @media ${screen.xsmall} {
      margin-left: 15px;
      margin-top: 0;
    }
  }

  .right-arrow {
    transition: ${transHover};
  }

  @media ${screen.withCursor} {
    &:hover {
      color: ${mainOrange};
    }

    &:hover .right-arrow {
      stroke: ${mainOrange};
    }
  }

  .break {
    display: block;
    @media ${screen.xsmall} {
      display: inline;
    }
  }
`;
// → label prop = text label
// → url prop = page url to go to
// → className prop = class for element
// → long prop = if the label is long, for different styling purposes
// → question prop = if its on the home page question, for different styling purposes
const LabeledArrow = ({ label, url, className, long, question, noclick }) => {
  const disableClick = e => {
    e.preventDefault();
  };
  return (
    <Wrapper
      long={long}
      className={className}
      to={url ? url : `/`}
      onClick={noclick && disableClick}
    >
      {!question ? (
        label
      ) : (
        <span>
          <span className="break">GOT QUESTIONS?</span> GET IN TOUCH WITH OUR
          TEAM
        </span>
      )}
      <RightArrow />
    </Wrapper>
  );
};

export default LabeledArrow;
