import React, { useContext, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { StoreContext } from "../context";
import styled from "styled-components";
import { mainBlue, screen } from "../components/common/variables";
import { GatsbyImage } from "gatsby-plugin-image";
import { flatten } from "../components/helpers";
import PageTitle from "../components/page-title";
import LabeledArrow from "../components/labeled-arrow";
import ProductSidebar from "../components/products/sidebar";

const Wrapper = styled.div`
  color: ${mainBlue};
  padding-bottom: 110px;
  padding-top: 73px;
  @media ${screen.xsmall} {
    padding-top: 87px;
    padding-bottom: 170px;
  }
  @media ${screen.large} {
    min-height: 62vh;
  }
  @media ${screen.xlarge} {
    padding-top: 91px;
    padding-bottom: 200px;
  }

  .content {
    padding-top: 35px;
    padding-left: 35px;
    padding-right: 35px;
    @media ${screen.xsmall} {
      padding-top: 40px;
      padding-left: 70px;
      padding-right: 70px;
    }
    @media ${screen.small} {
      padding-top: 80px;
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.medium} {
      padding-left: 160px;
      padding-right: 160px;
    }
    @media ${screen.xlarge} {
      padding-left: 280px;
      padding-right: 280px;
    }

    .keyword {
      font-size: 1.17rem;
      font-weight: 300;
      margin-top: 5px;
      margin-bottom: 42px;
      @media ${screen.xsmall} {
        font-size: 1.4rem;
        margin-top: 30px;
        margin-bottom: 82px;
      }
      @media ${screen.xlarge} {
        font-size: 1.5rem;
      }

      span {
        font-weight: 800;
      }
    }

    .products-result {
      &__heading {
        font-size: 1.7rem;
        font-weight: 300;
        line-height: 1.25;
        @media ${screen.xsmall} {
          font-size: 2.4rem;
        }
        @media ${screen.small} {
          font-size: 2.55rem;
        }

        @media ${screen.medium} {
          font-size: 2.9rem;
        }
        @media ${screen.xlarge} {
          font-size: 3.1rem;
        }
      }

      &__list {
        margin-top: 22px;
        border-bottom: 1px solid ${mainBlue};
        @media ${screen.xsmall} {
          margin-top: 45px;
          padding-bottom: 45px;
        }
        @media ${screen.small} {
          margin-top: 65px;
          padding-bottom: 65px;
        }

        .product-item {
          display: flex;
          flex-direction: column;
          margin-bottom: 60px;
          @media ${screen.xsmall} {
            flex-direction: row;
            margin-bottom: 60px;
          }

          &__img {
            width: 100%;
            max-width: 190px;
            margin-bottom: 10px;
            cursor: pointer;
            @media ${screen.xsmall} {
              max-width: 190px;
              margin-bottom: 0;
            }
            @media ${screen.small} {
              max-width: 420px;
            }
          }

          &__text {
            max-width: 800px;
            @media ${screen.xsmall} {
              margin-left: 40px;
            }
            @media ${screen.small} {
              margin-left: 60px;
            }

            .product-name {
              cursor: pointer;
              font-size: 1.25rem;
              font-weight: 800;
              margin-bottom: 12px;
              @media ${screen.xsmall} {
                font-size: 2rem;
              }
              @media ${screen.small} {
                font-size: 2.4rem;
              }
            }

            .product-description {
              p {
                font-size: 1rem;
                margin-bottom: 18px;
                @media ${screen.xsmall} {
                  font-size: 1.08rem;
                  margin-bottom: 22px;
                }
                @media ${screen.xlarge} {
                  font-size: 1.25rem;
                  margin-bottom: 30px;
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }

            .product-link {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
`;

const SearchPage = (props) => {
  const {
    addOrder,
    selectedProduct,
    setProductSidebar,
    isProductSidebarOpen,
    setProduct,
    removeUrlSearch,
    orders,
  } = useContext(StoreContext);

  const [productType, setProductType] = useState("lance");

  const keyword =
    typeof window !== "undefined" &&
    window.location.search.substring(1).replace(/-/g, " ");

  const lances = props.data.lances.edges.map((product) => {
    return { ...product, type: "lance" };
  });

  const parts = props.data.parts.edges.map((product) => {
    return { ...product, type: "lance" };
  });
  const kits = props.data.kits.edges.map((product) => {
    return { ...product, type: "lance" };
  });
  const safety = props.data.safety.edges.map((product) => {
    return { ...product, type: "lance" };
  });
  const handles = props.data.handles.edges.map((product) => {
    return { ...product, type: "lance" };
  });
  const pipes = props.data.pipes.edges.map((product) => {
    return { ...product, type: "lance" };
  });
  const lit = props.data.lit.edges.map((product) => {
    return { ...product, type: "lance" };
  });

  const ladles = props.data.ladles.edges.map((product) => {
    return { ...product, type: "foundry" };
  });
  const skimmers = props.data.skimmers.edges.map((product) => {
    return { ...product, type: "foundry" };
  });
  const tongs = props.data.tongs.edges.map((product) => {
    return { ...product, type: "foundry" };
  });
  const plungers = props.data.plungers.edges.map((product) => {
    return { ...product, type: "foundry" };
  });

  const blowingEquipments = props.data.blowingEquipments.edges.map(
    (product) => {
      return { ...product, type: "contessi" };
    }
  );

  const safetyContessi = props.data.safetyContessi.edges.map((product) => {
    return { ...product, type: "contessi" };
  });

  const plantEquipments = props.data.plantEquipments.edges.map((product) => {
    return { ...product, type: "contessi" };
  });

  const products = flatten([
    lances,
    parts,
    kits,
    safety,
    handles,
    pipes,
    lit,
    ladles,
    skimmers,
    tongs,
    plungers,
    blowingEquipments,
    safetyContessi,
    plantEquipments,
  ]);

  const productResults =
    keyword.length > 2
      ? products.filter((product) => {
          return product.node.data.name.text.toLowerCase().includes(keyword);
        })
      : [];

  // total results length
  const totalLength = productResults.length;

  return (
    <Layout>
      <SEO title="Search | Australian Thermic Lance Company" />
      <Wrapper>
        <div className="content">
          <PageTitle>Search</PageTitle>
          <p className="keyword">
            Your search for <span>{keyword}</span> produced {totalLength} result
            {totalLength !== 1 && "s"}.
          </p>
          {productResults.length > 0 && (
            <div className="products-result">
              <h3 className="products-result__heading">Products</h3>
              <ul className="products-result__list">
                {productResults.map((product) => (
                  <li
                    onClick={() => {
                      setProduct({
                        id: product.node.id,
                        name: product.node.data.name.text,
                        description:
                          product.node.data.description &&
                          product.node.data.description.html,
                        images:
                          product.node.data.images.length &&
                          product.node.data.images,
                        column1Heading:
                          product.node.data.column_1_heading &&
                          product.node.data.column_1_heading.text,
                        column2Heading:
                          product.node.data.column_2_heading &&
                          product.node.data.column_2_heading.text,
                        column3Heading:
                          product.node.data.column_3_heading &&
                          product.node.data.column_3_heading.text,
                        column4Heading:
                          product.node.data.column_4_heading &&
                          product.node.data.column_4_heading.text,
                        column5Heading:
                          product.node.data.column_5_heading &&
                          product.node.data.column_5_heading.text,
                        column6Heading:
                          product.node.data.column_6_heading &&
                          product.node.data.column_6_heading.text,
                        column1Subheading:
                          product.node.data.column_1_subheading,
                        column2Subheading:
                          product.node.data.column_2_subheading,
                        column3Subheading:
                          product.node.data.column_3_subheading,
                        column4Subheading:
                          product.node.data.column_4_subheading,
                        column5Subheading:
                          product.node.data.column_5_subheading,
                        column6Subheading:
                          product.node.data.column_6_subheading,
                        tableRows: product.node.data.rows,
                      });
                      setProductType(product.type);
                      setProductSidebar(true);
                    }}
                    className="product-item"
                    key={product.node.id}
                  >
                    <div className="product-item__img">
                      {product.node.data.images[0].image.gatsbyImageData && (
                        <GatsbyImage
                          image={
                            product.node.data.images[0].image.gatsbyImageData
                          }
                          alt={
                            product.node.data.images[0].image.alt || "Thumbnail"
                          }
                        />
                      )}
                    </div>

                    <div className="product-item__text">
                      <h3 className="product-name">
                        {product.node.data.name.text}
                      </h3>
                      <div
                        className="product-description"
                        dangerouslySetInnerHTML={{
                          __html: product.node.data.description.html,
                        }}
                      />
                      <LabeledArrow
                        className="product-link"
                        label={`VIEW PRODUCT`}
                        url={`/`}
                        noclick
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <ProductSidebar
          isProductSidebarOpen={isProductSidebarOpen}
          setProduct={setProduct}
          selectedProduct={selectedProduct}
          setProductSidebar={setProductSidebar}
          removeUrlSearch={removeUrlSearch}
          addOrder={addOrder}
          orders={orders}
          productType={productType}
          // urlPath={urlPath}
        />
      </Wrapper>
    </Layout>
  );
};

export default SearchPage;

export const dataQuery = graphql`
  {
    lances: allPrismicThermicLance {
      edges {
        node {
          id
          data {
            name {
              text
            }
            description {
              html
            }
            images {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            column_1_heading {
              text
            }
            column_2_heading {
              text
            }
            column_3_heading {
              text
            }
            column_4_heading {
              text
            }
            column_5_heading {
              text
            }
            column_6_heading {
              text
            }
            column_1_subheading
            column_2_subheading
            column_3_subheading
            column_4_subheading
            column_5_subheading
            column_6_subheading
            rows {
              column_1_data
              column_2_data
              column_3_data
              column_4_data
              column_5_data
              column_6_data
            }
          }
        }
      }
    }
    parts: allPrismicSparePartThermicLanceAccessory {
      edges {
        node {
          id
          data {
            name {
              text
            }
            description {
              html
            }
            images {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            column_1_heading {
              text
            }
            column_2_heading {
              text
            }
            column_3_heading {
              text
            }
            column_4_heading {
              text
            }
            column_5_heading {
              text
            }
            column_6_heading {
              text
            }
            column_1_subheading
            column_2_subheading
            column_3_subheading
            column_4_subheading
            column_5_subheading
            column_6_subheading
            rows {
              column_1_data
              column_2_data
              column_3_data
              column_4_data
              column_5_data
              column_6_data
            }
          }
        }
      }
    }
    kits: allPrismicKitThermicLanceAccessory {
      edges {
        node {
          id
          data {
            name {
              text
            }
            description {
              html
            }
            images {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            column_1_heading {
              text
            }
            column_2_heading {
              text
            }
            column_3_heading {
              text
            }
            column_4_heading {
              text
            }
            column_5_heading {
              text
            }
            column_6_heading {
              text
            }
            column_1_subheading
            column_2_subheading
            column_3_subheading
            column_4_subheading
            column_5_subheading
            column_6_subheading
            rows {
              column_1_data
              column_2_data
              column_3_data
              column_4_data
              column_5_data
              column_6_data
            }
          }
        }
      }
    }
    safety: allPrismicSafetyThermicLanceAccessory {
      edges {
        node {
          id
          data {
            name {
              text
            }
            description {
              html
            }
            images {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            column_1_heading {
              text
            }
            column_2_heading {
              text
            }
            column_3_heading {
              text
            }
            column_4_heading {
              text
            }
            column_5_heading {
              text
            }
            column_6_heading {
              text
            }
            column_1_subheading
            column_2_subheading
            column_3_subheading
            column_4_subheading
            column_5_subheading
            column_6_subheading
            rows {
              column_1_data
              column_2_data
              column_3_data
              column_4_data
              column_5_data
              column_6_data
            }
          }
        }
      }
    }
    handles: allPrismicHandleThermicLanceAccessory {
      edges {
        node {
          id
          data {
            name {
              text
            }
            description {
              html
            }
            images {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            column_1_heading {
              text
            }
            column_2_heading {
              text
            }
            column_3_heading {
              text
            }
            column_4_heading {
              text
            }
            column_5_heading {
              text
            }
            column_6_heading {
              text
            }
            column_1_subheading
            column_2_subheading
            column_3_subheading
            column_4_subheading
            column_5_subheading
            column_6_subheading
            rows {
              column_1_data
              column_2_data
              column_3_data
              column_4_data
              column_5_data
              column_6_data
            }
          }
        }
      }
    }
    pipes: allPrismicCalorisedLancePipeThermicLanceAccessory {
      edges {
        node {
          id
          data {
            name {
              text
            }
            description {
              html
            }
            images {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            column_1_heading {
              text
            }
            column_2_heading {
              text
            }
            column_3_heading {
              text
            }
            column_4_heading {
              text
            }
            column_5_heading {
              text
            }
            column_6_heading {
              text
            }
            column_1_subheading
            column_2_subheading
            column_3_subheading
            column_4_subheading
            column_5_subheading
            column_6_subheading
            rows {
              column_1_data
              column_2_data
              column_3_data
              column_4_data
              column_5_data
              column_6_data
            }
          }
        }
      }
    }
    lit: allPrismicLitProcessThermicLanceAccessory {
      edges {
        node {
          id
          data {
            name {
              text
            }
            description {
              html
            }
            images {
              image {
                alt

                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            column_1_heading {
              text
            }
            column_2_heading {
              text
            }
            column_3_heading {
              text
            }
            column_4_heading {
              text
            }
            column_5_heading {
              text
            }
            column_6_heading {
              text
            }
            column_1_subheading
            column_2_subheading
            column_3_subheading
            column_4_subheading
            column_5_subheading
            column_6_subheading
            rows {
              column_1_data
              column_2_data
              column_3_data
              column_4_data
              column_5_data
              column_6_data
            }
          }
        }
      }
    }
    ladles: allPrismicLadleFoundry {
      edges {
        node {
          id
          data {
            name {
              text
            }
            description {
              html
            }
            images {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            column_1_heading {
              text
            }
            column_2_heading {
              text
            }
            column_3_heading {
              text
            }
            column_4_heading {
              text
            }
            column_5_heading {
              text
            }
            column_6_heading {
              text
            }
            column_1_subheading
            column_2_subheading
            column_3_subheading
            column_4_subheading
            column_5_subheading
            column_6_subheading
            rows {
              column_1_data
              column_2_data
              column_3_data
              column_4_data
              column_5_data
              column_6_data
            }
          }
        }
      }
    }
    skimmers: allPrismicSkimmerFoundry {
      edges {
        node {
          id
          data {
            name {
              text
            }
            description {
              html
            }
            images {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            column_1_heading {
              text
            }
            column_2_heading {
              text
            }
            column_3_heading {
              text
            }
            column_4_heading {
              text
            }
            column_5_heading {
              text
            }
            column_6_heading {
              text
            }
            column_1_subheading
            column_2_subheading
            column_3_subheading
            column_4_subheading
            column_5_subheading
            column_6_subheading
            rows {
              column_1_data
              column_2_data
              column_3_data
              column_4_data
              column_5_data
              column_6_data
            }
          }
        }
      }
    }
    tongs: allPrismicTongsFoundry {
      edges {
        node {
          id
          data {
            name {
              text
            }
            description {
              html
            }
            images {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            column_1_heading {
              text
            }
            column_2_heading {
              text
            }
            column_3_heading {
              text
            }
            column_4_heading {
              text
            }
            column_5_heading {
              text
            }
            column_6_heading {
              text
            }
            column_1_subheading
            column_2_subheading
            column_3_subheading
            column_4_subheading
            column_5_subheading
            column_6_subheading
            rows {
              column_1_data
              column_2_data
              column_3_data
              column_4_data
              column_5_data
              column_6_data
            }
          }
        }
      }
    }
    plungers: allPrismicDegassingPlungerFoundry {
      edges {
        node {
          id
          data {
            name {
              text
            }
            description {
              html
            }
            images {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            column_1_heading {
              text
            }
            column_2_heading {
              text
            }
            column_3_heading {
              text
            }
            column_4_heading {
              text
            }
            column_5_heading {
              text
            }
            column_6_heading {
              text
            }
            column_1_subheading
            column_2_subheading
            column_3_subheading
            column_4_subheading
            column_5_subheading
            column_6_subheading
            rows {
              column_1_data
              column_2_data
              column_3_data
              column_4_data
              column_5_data
              column_6_data
            }
          }
        }
      }
    }
    blowingEquipments: allPrismicBlowingEquipmentContessiProduct {
      edges {
        node {
          id
          data {
            name {
              text
            }
            description {
              html
            }
            images {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            column_1_heading {
              text
            }
            column_2_heading {
              text
            }
            column_3_heading {
              text
            }
            column_4_heading {
              text
            }
            column_5_heading {
              text
            }
            column_6_heading {
              text
            }
            column_1_subheading
            column_2_subheading
            column_3_subheading
            column_4_subheading
            column_5_subheading
            column_6_subheading
            rows {
              column_1_data
              column_2_data
              column_3_data
              column_4_data
              column_5_data
              column_6_data
            }
          }
        }
      }
    }
    safetyContessi: allPrismicSafetyContessiProduct {
      edges {
        node {
          id
          data {
            name {
              text
            }
            description {
              html
            }
            images {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            column_1_heading {
              text
            }
            column_2_heading {
              text
            }
            column_3_heading {
              text
            }
            column_4_heading {
              text
            }
            column_5_heading {
              text
            }
            column_6_heading {
              text
            }
            column_1_subheading
            column_2_subheading
            column_3_subheading
            column_4_subheading
            column_5_subheading
            column_6_subheading
            rows {
              column_1_data
              column_2_data
              column_3_data
              column_4_data
              column_5_data
              column_6_data
            }
          }
        }
      }
    }
    plantEquipments: allPrismicPlantEquipmentContessiProduct {
      edges {
        node {
          id
          data {
            name {
              text
            }
            description {
              html
            }
            images {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            column_1_heading {
              text
            }
            column_2_heading {
              text
            }
            column_3_heading {
              text
            }
            column_4_heading {
              text
            }
            column_5_heading {
              text
            }
            column_6_heading {
              text
            }
            column_1_subheading
            column_2_subheading
            column_3_subheading
            column_4_subheading
            column_5_subheading
            column_6_subheading
            rows {
              column_1_data
              column_2_data
              column_3_data
              column_4_data
              column_5_data
              column_6_data
            }
          }
        }
      }
    }
  }
`;
